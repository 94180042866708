import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Aerospace Parts Manufacturing | Painted Rhino"
            description="Aerospace parts manufacturing is a process that involves the production of parts and components for use in aircraft and space vehicles. "
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Painted Rhino: Aerospace Parts Manufacturing"
            date="June 15, 2022"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Aerospace parts manufacturing is a process that involves the production of parts and components for use in aircraft and space
                     vehicles.
                  </p>

                  <p>This process can be divided into two main categories: original equipment manufacturing (OEM) and aftermarket manufacturing.</p>

                  <p>
                     OEM manufacturers produce parts and components used in the construction of new aircraft and space vehicles, while aftermarket
                     manufacturers produce replacement parts and components for existing aircraft and space vehicles.
                  </p>

                  <h2>The Manufacturing Process</h2>
                  <p>The aerospace parts manufacturing process begins with the design of the part or component to be produced.</p>
                  <p>
                     Once the design is complete, the manufacturer must procure the necessary raw materials, including metals, composites, plastics,
                     and other materials.
                  </p>
                  <p>
                     The raw materials are then cut, shaped, and formed into the desired part or component using a variety of manufacturing processes,
                     such as machining, casting, and molding.
                  </p>
                  <p>
                     Once the part or component is completed, it undergoes a series of quality assurance tests to ensure that it meets all of the
                     required specifications.
                  </p>

                  <h2>Types of Aerospace Parts Manufacturers</h2>
                  <p>
                     There are several different types of aerospace parts manufacturers, which include small shops, large corporations, and
                     government-owned facilities.
                  </p>
                  <p>
                     Small shops typically specialize in producing a limited number of parts or components, while large corporations may produce a
                     wide variety of parts and components.
                  </p>
                  <p>Government-owned facilities are responsible for producing parts and components for military aircraft and space vehicles.</p>
                  <p>
                     The global aerospace parts manufacturing market is expected to grow in the coming years. This growth is primarily due to the
                     increasing demand for aircraft and space vehicles and the need for replacement parts and components for existing aircraft and
                     space vehicles.
                  </p>

                  <h2>Machine Parts Used in Aerospace Parts Manufacturing</h2>
                  <p>Many different machine parts are used in aerospace parts manufacturing, including:</p>
                  <ul>
                     <li>Electrical connectors</li>
                     <li>Air and liquid-based filtration solutions</li>
                     <li>Engine housings</li>
                     <li>Turbine blades</li>
                     <li>Oxygen production solutions</li>
                     <li>Motion controls</li>
                     <li>Actuators</li>
                     <li>Landing gear parts</li>
                     <li>Fuselage parts</li>
                     <li>Seats and armrests</li>
                     <li>Wing ribs</li>
                     <li>Cabin components</li>
                     <li>Trays</li>
                     <li>Shafts</li>
                     <li>Discs</li>
                  </ul>
                  <p>
                     As you can see, a lot goes into the manufacturing of aerospace components. But that's not all—there is also the need for the
                     right materials.
                  </p>

                  <h2>Materials Used in Aerospace Parts Manufacturing</h2>
                  <p>Many different materials are used in aerospace parts manufacturing, including:</p>

                  <ul>
                     <li>
                        <h3>Aluminum Alloy</h3>
                        <p>
                           Aluminum alloy is the most commonly used material in aerospace parts manufacturing because of its lightweight yet strong
                           composition, which can easily form into the desired shape.
                        </p>
                        <p>
                           In addition, aluminum alloy is resistant to corrosion, an important factor for aircraft that fly in saltwater environments.
                        </p>
                     </li>
                     <li>
                        <h3>Titanium Alloy</h3>
                        <p>
                           Titanium alloy is another common material used in aerospace parts manufacturing due to its lightweight and high
                           strength-to-weight ratio. Not to be left behind, titanium alloy is also resistant to corrosion.
                        </p>
                     </li>
                     <li>
                        <h3>Inconel Superalloy</h3>
                        <p>
                           Inconel is a type of superalloy known for its strength and durability. Made from nickel, chromium, and iron, it's often
                           used in aerospace parts manufacturing and space vehicles because it has a high resistance to heat and corrosion.
                        </p>
                     </li>
                  </ul>

                  <h2>Contact Painted Rhino for Superior Fabrication and Manufacturing</h2>
                  <p>
                     Whether you’re looking for <a href="/fiberglass-composite-parts-manufacturing/">fiberglass or composite parts manufacturing</a>,
                     at Painted Rhino, we have the experience and expertise to produce high-quality parts and components that meet the strictest
                     quality standards. <a data-modal-open="modal-contact"> Contact us to get a quote!</a>
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Painted Rhino_Aerospace parts manufacturing.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
